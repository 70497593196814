/* eslint-disable react/no-danger */
import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { hexToRgb } from '../../utils/helpers';
import { BREAKPOINT, COLOR } from '../../utils/constants';
import './_hero.scss';

const RGBBlue = hexToRgb(COLOR.BLUE);

const StyledHero = styled.div`
  ${({ bg }) =>
    bg && bg !== 'brand--primary' && `background-image: url(${bg});`};

  ${({ backgroundPosition }) =>
    backgroundPosition && `background-position: ${backgroundPosition};`};

  @media (min-width: ${BREAKPOINT.LG}) {
    ${({ backgroundPositionLg }) =>
      `background-position: ${backgroundPositionLg};`}
  }
`;

const ContentContainer = styled.div`
  z-index: 1;
  @media (max-width: ${BREAKPOINT.MD}) {
    padding-right: 15px;
    padding-left: 15px;

    background: linear-gradient(
      180deg,
      rgba(${RGBBlue}, 0.8) 0%,
      ${COLOR.BLUE} 100px
    );
    z-index: 2;
  }

  @media (min-width: ${BREAKPOINT.MD}) {
    ${({ contentMaxWidth }) =>
      contentMaxWidth ? `max-width: ${contentMaxWidth}` : 'max-width: 60%'}
  }
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    ${({ contentMaxWidth }) =>
      contentMaxWidth ? `max-width: ${contentMaxWidth}` : 'max-width: 55%'}
  }
`;

export default props => {
  return (
    <StyledHero
      {...props}
      style={props.style}
      className={classnames(
        'hero',
        {
          'hero--dark-bg': props.darkBg,
          'hero--bg--brand--primary': props.bg === 'brand--primary',
          'hero--center-bg': props.centerBg,
          'hero--md-height': props.mediumHeight
        },
        props.className,
        props.introTextcolor && `hero__intro--${props.introTextcolor}`
      )}
    >
      {props.bgAriaLabel && <span role="img" aria-label={props.bgAriaLabel} />}
      <div className="container d-flex flex-column-reverse flex-md-row">
        <ContentContainer contentMaxWidth={props.contentMaxWidth}>
          <div className="container p-0 hero-content pb-4">
            {props.h1 && <h1>{props.h1}</h1>}
            {props.h2 && <h4 dangerouslySetInnerHTML={{ __html: props.h2 }} />}
            {props.intro && (
              <p className="hero__intro">
                <strong dangerouslySetInnerHTML={{ __html: props.intro }} />
              </p>
            )}
            {props.children}
          </div>
        </ContentContainer>
        {props.image}
      </div>
    </StyledHero>
  );
};
