import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from '@latitude/link';
import { Text } from '@latitude/text';
import {
  COLOR,
  PADDING,
  FONT_FAMILY,
  FONT_WEIGHT,
  BREAKPOINT
} from '../../utils/constants';


const ScamArticle = ({ isBranded, thumbnail, thumbnail2, path, title, title2, path2, prepath, pretitle }) => {
  const ArticleTile = styled(Link)`
    display: block;
    position: relative;
    min-height: 245px;
    overflow: hidden;
    margin-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    
    &:first-child {
      background-image: url(/scams-hub/images/${thumbnail});
    }
    
    &:nth-child(2) {
      background-image: url(/scams-hub/images/${thumbnail2});
    }

    ${isBranded &&
      css`
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 84px;
        background-color: ${COLOR.WHITE};
        &:after {
          content: '';
          background-color: ${COLOR.WHITE};
          width: 100%;
          height: 45px;
          transform: skew(0deg, -6deg);
          position: absolute;
          bottom: 59px;
          left: 0;
          z-index: 3;
        }
      `}
  `;

  const ArticleTextContainer = styled.div`
    position: absolute;
    bottom: 0;
    height: 110px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLOR.BLUE};
    padding: ${PADDING.P16};
    z-index: 4;

    ${isBranded &&
      css`
        background-color: ${COLOR.WHITE};
        height: 84px;
        padding: 16px 16px 24px;
        p {
          font-family: ${FONT_FAMILY.TITLE};
          font-weight: ${FONT_WEIGHT.SEMI_BOLD};
          color: ${COLOR.BLACK};
          font-size: 16px;
        }
      `}
  `;

  return (
    <div>
      {/* First Thumbnail and Title */}
      <ArticleTile href={prepath} trackEventData={{ label: pretitle }} noStyle>
        <ArticleTextContainer>
          <Text color="white" align="center">
          <b>{pretitle}</b>
          </Text>
        </ArticleTextContainer>
      </ArticleTile>

      {/* Second Thumbnail and Title */}
      {path2 && (<ArticleTile href={path2} trackEventData={{ label: title2 }} noStyle>
        <ArticleTextContainer>
          <Text color="white" align="center">
            <b>{title2}</b>
          </Text>
        </ArticleTextContainer>
      </ArticleTile>)}
    </div>
  );
};

export default ScamArticle;
