import React from 'react';
import rehypeReact from 'rehype-react';
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6
} from '../Heading/Heading';
import Text from '../Text/Text';

const Remark = props => {
  const {
    H1Component,
    H2Component,
    H3Component,
    H4Component,
    H5Component,
    H6Component,
    PComponent
  } = props;

  const H1Overide = ({ children }) =>
    React.cloneElement(H1Component, null, children);
  const H2Overide = ({ children }) =>
    React.cloneElement(H2Component, null, children);
  const H3Overide = ({ children }) =>
    React.cloneElement(H3Component, null, children);
  const H4Overide = ({ children }) =>
    React.cloneElement(H4Component, null, children);
  const H5Overide = ({ children }) =>
    React.cloneElement(H5Component, null, children);
  const H6Overide = ({ children }) =>
    React.cloneElement(H6Component, null, children);
  const POveride = ({ children }) =>
    React.cloneElement(PComponent, null, children);

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      h1: H1Component ? H1Overide : Heading1,
      h2: H2Component ? H2Overide : Heading2,
      h3: H3Component ? H3Overide : Heading3,
      h4: H4Component ? H4Overide : Heading4,
      h5: H5Component ? H5Overide : Heading5,
      h6: H6Component ? H6Overide : Heading6,
      p: PComponent ? POveride : Text
    }
  }).Compiler;

  return renderAst(props.htmlAst);
};

export default Remark;
