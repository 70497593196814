/* eslint-disable import/no-dynamic-require */
import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Hero from '../../components/Hero/Hero';
import Section from '../../components/Section/Section';
import ArticleTile from './ArticleTile';
import Layout from '../../components/layout';
import Remark from '../../components/Remark/Remark';
import { Heading2, Heading3, Heading4 } from '../../components/Heading/Heading';
import Text from '../../components/Text/Text';
import { COLOR, MARGIN } from '../../utils/constants';

import './article.scss';

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, htmlAst } = markdownRemark;
  const {
    id,
    title,
    title2,
    path2,
    pretitle,
    prepath,
    author,
    meta_title,
    meta_description,
    thumbnail,
    thumbnail2,
    heroBg,
    pageHeader,
  } = frontmatter;

  const baseUrl = 'https://www.gemfinance.co.nz/scams-hub/';
  const filepath = `${id}.html`;
  const url = encodeURI(`${baseUrl}/${filepath}`);

  const encodedTitle = encodeURI(title);

  return (
    <Layout noBreadcrumb>
      <main className="navigation-spacer">
        <Helmet
          title={meta_title ? meta_title : `${title} | Latitude Financial`}
        >
          {meta_description && (
            <meta name="description" content={meta_description} />
          )}

          <link rel="canonical" href={url} />
          <link rel="amphtml" href={`${baseUrl}/amp/${filepath}`} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={meta_description} />
          <meta
            property="og:image"
            content={`${baseUrl}/images/${thumbnail}`}
          />
          <meta property="og:url" content={url} />
          <meta property="og:meta_title" content={meta_title} />
          <meta property="og:meta_description" content={meta_description} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={meta_description} />
          <meta
            name="twitter:image"
            content={`${baseUrl}/images/${thumbnail}`}
          />
          <meta name="twitter:image:alt" content={title} />
        </Helmet>

        <Hero
          intro={author ? `By ${author}` : ''}
          darkBg
          bg={
            require(`../../../static/scams-hub/images/${heroBg}`)
          }
        />

        <Section className="bg-white life-done-better">
          <div className="row">
            <div className="col-lg-8">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/scams-hub">Latitude Security & Scams Hub</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {title}
                  </li>
                </ol>
              </nav>
              <h4 className="page-title">{pageHeader}</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8">
              <Remark
                htmlAst={htmlAst}
                H2Component={
                  <Heading2 color={COLOR.BLUE_DEEP} marginTop={MARGIN.M32} className="heading2"/>
                }
                H3Component={<Heading3 color={COLOR.BLUE} />}
                H4Component={<Heading4 color={COLOR.BLUE} />}
                PComponent={
                  <Text marginTop={MARGIN.M16} marginBottom={MARGIN.M16} />
                }
              />
            </div>

            <div className="col-lg-4 latest-articles">
              <h4>Latest alerts:</h4>
              {/* {allMarkdownRemark.edges.map(item => {
                const article = item.node.frontmatter;
                return <ArticleTile key={article.path} {...article} />;
              })} */}
            {allMarkdownRemark.edges
            .filter(item => {
              const article = item.node.frontmatter;
              return article.id === id; // Replace 'id' with your specific id
            })
            .map(item => {
              const article = item.node.frontmatter;
              return <ArticleTile 
              key={article.path} 
              {...article}
              thumbnail2={article.thumbnail2}
              title2 ={title2}
              path2={path2}
              pretitle ={pretitle}
              prepath ={prepath}
            />
            
            })}
              
            </div>
          </div>
        </Section>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ScamArticleByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        path2
        id
        title
        title2
        pretitle
        prepath
        thumbnail
        thumbnail2
        heroBg
        meta_title
        meta_description,
        pageHeader
      }
    }

    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/scams-hub/articles/*.md"
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 5
    ) {
      edges {
        node {
          html
          frontmatter {
            id
            title
            title2
            pretitle
            prepath
            description
            date
            path
            path2
            thumbnail
            thumbnail2
          }
        }
      }
    }
  }
`;
